import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
    selectUserLoading,
    selectInHouse,
    selectUserProductType,
    ProductType,
} from '../store/reducers/userSlice';
import LoadPage from './misc/LoadPage';
import { TempPage } from './misc/TempPage';

export const InHouse = () => {
    const navigate = useNavigate();

    const inHouse = useSelector(selectInHouse);
    const userLoading = useSelector(selectUserLoading);
    const productType = useSelector(selectUserProductType)

    const inHouseLink = useCallback(()  => {
        let inHouseLink = '';
        if (inHouse && inHouse.isFirm) {
            inHouseLink = `/firms/${encodeURI(inHouse.inHouseName)}/?name=${encodeURI(inHouse.inHouseName)}`;
        } else if (inHouse) {
            inHouseLink = `/sectors/${inHouse.sectorCode}/organizations/${encodeURI(inHouse.inHouseName)}?sector=${encodeURI(inHouse.sectorName!)}`;
        }
        return inHouseLink;
    }, [inHouse]);

    // redirect user to their in-house dashboard on render
    useEffect(() => {
        if (!userLoading && inHouse) {
            navigate(inHouseLink());
        }
    }, [navigate, userLoading, inHouse, inHouseLink]);

    const unassignedText = productType === ProductType.TRIAL ? "In-house dashboard available with subscription" : "Please wait up to 48 hours for LobbyIQ to provision your in-house dashboard";

    return userLoading || inHouse ? (
        <LoadPage />
    ) : (
        <TempPage text={unassignedText}/>
    );
};
