import React, { useEffect } from 'react';
import { useAppDispatch } from '../../../store/store';
import EntityListPage from '../../../pages/lists/EntityListPage';
import { useSelector } from 'react-redux';
import {
    fetchSenateCommitteeList,
    fetchSenatorList,
    selectSenateCommitteeList,
    selectSenatorList,
} from '../store/senateListSlice';
import { entityListExtra } from '../../../interfaces/generic-entity.interface';
import {
    fetchCommitteesSelections,
    selectChosenCommittees,
    Selection,
} from '../../../store/reducers/customSelectionSlice';

export enum senateType {
    Members,
    Cmtes,
}

const SenateListDataLayer = ({ listType }: { listType: senateType }) => {
    const dispatch = useAppDispatch();

    let type: string;
    switch (listType) {
        case senateType.Members:
            type = 'senators';
            break;
        case senateType.Cmtes:
            type = 'senate committees';
            break;
    }

    const entity_list: entityListExtra[] | null = useSelector(
        listType === senateType.Members ? selectSenatorList : selectSenateCommitteeList
    );

    const committeeSelections: Selection[] = useSelector(selectChosenCommittees);

    useEffect(() => {
        if (listType === senateType.Cmtes) {
            dispatch(fetchSenateCommitteeList());
            dispatch(fetchCommitteesSelections());
        } else {
            dispatch(fetchSenatorList());
        }
    }, [dispatch, listType]);

    return (
        <EntityListPage
            type={type as any}
            list={entity_list || []}
            // filter out house committees
            selections={committeeSelections
                .filter((committee) => committee.title.slice(0, 3) === 'S -')
                .map((committee) => ({
                    title: committee.title.slice(4),
                    code: committee.code,
                }))}
            defaultSortColumn="title"
            defaultSortOrder="asc"
        />
    );
};

export default SenateListDataLayer;
