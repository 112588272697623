import { MP_FN } from '../dpoh-dashboards/constants';

enum EntityCategory {
    CORE,
    PERSONAL,
    GLOBAL,
    COMMITTEE,
    ISSUE,
}

/**
 * Entity
 * Represents a single dashboard entity in the application
 * @param {string} accessLevel: refers to the accessibility level of the entity
 */
class Entity {
    identifier: string;
    name: string;
    path: string;
    category: EntityCategory;
    // The level from which the entity list can be accessed (ex: sectors=1, orgs=2)
    accessLevel: number;

    constructor(
        identifier: string,
        name: string,
        path: string,
        category: EntityCategory,
        accessLevel: number
    ) {
        this.identifier = identifier;
        this.name = name;
        this.path = path;
        this.category = category;
        this.accessLevel = accessLevel;
    }

    getFormattedTitle(): string {
        let title = this.name;

        if (!title) {
            return '';
        }

        let arr = [];
        let excludeWords = ['of', 'the', 'and'];
        arr = title.split(' ');

        return arr
            .map((word) => {
                return excludeWords.includes(word)
                    ? [word]
                    : word.charAt(0).toUpperCase() + word.slice(1);
            })
            .join(' ');
    }
}

// TOP LEVEL ENTITIES
var topLevelCoreEntities: Entity[] = [
    new Entity(
        'mp',
        'MPs',
        `/institutions/103/dpoh?institution=${encodeURIComponent(
            'House of Commons'
        )}&title=${encodeURIComponent(MP_FN)}`,
        EntityCategory.CORE,
        1
    ),
    new Entity('senator', 'senators', '/senate/members', EntityCategory.CORE, 1),
    new Entity('sector', 'sectors', '/sectors', EntityCategory.CORE, 1),
    new Entity('institution', 'institutions', '/institutions', EntityCategory.CORE, 1),
    new Entity('subject', 'subjects', '/subjects', EntityCategory.CORE, 1),
    new Entity('firm', 'lobby firms', '/firms', EntityCategory.CORE, 1),
];

var topLevelPersonalEntities: Entity[] = [
    new Entity('in-house', 'in-house', '/in-house', EntityCategory.PERSONAL, 1),
];

var topLevelGlobalEntities: Entity[] = [
    new Entity('big-picture', 'big picture', '/big-picture', EntityCategory.GLOBAL, 1),
    new Entity('custom-query', 'custom query', '/custom-query', EntityCategory.GLOBAL, 1),
];

var topLevelIssueEntities: Entity[] = [
    new Entity('issue', 'issues', '/issues', EntityCategory.ISSUE, 1),
];

var topLevelCommitteeEntities: Entity[] = [
    new Entity('committees', 'house committees', '/committees', EntityCategory.COMMITTEE, 1),
    new Entity(
        'senate-cmte',
        'senate committees',
        '/senate/committees',
        EntityCategory.COMMITTEE,
        1
    ),
];

const entitiesList = topLevelCoreEntities.concat(
    topLevelPersonalEntities,
    topLevelGlobalEntities,
    topLevelCommitteeEntities,
    topLevelIssueEntities
);

// Throw an error if the list is not a unique set
if (
    Array.from(new Set(entitiesList.map((x: Entity) => x.identifier))).length !==
        entitiesList.length ||
    Array.from(new Set(entitiesList.map((x: Entity) => x.path))).length !== entitiesList.length
) {
    throw new Error(
        'The raw entities list is not unique, some entries are sharing the same identifier / path'
    );
}

/**
 * EntityRepo
 * Responsible for keeping record of a static constant list of entities which exist as dashboards in the application
 * Entities can be retrieved by keys at any time through a set of static member functions
 */
class EntityRepo {
    private static entities: Entity[] = entitiesList;

    static getEntityList() {
        return EntityRepo.entities;
    }

    static getEntityListByAccessLevel(level: number) {
        return EntityRepo.entities.filter((x: Entity) => x.accessLevel === level);
    }

    static getEntityListByCategory(cat: EntityCategory) {
        return EntityRepo.entities.filter((x: Entity) => x.category === cat);
    }

    static getEntityByIdentifier(id: string): Entity | null {
        return EntityRepo.entities.filter((x: Entity) => x.identifier === id)[0];
    }

    static getEntityByPath(path: string): Entity {
        // Ignore query params following a "?" if one exists in the path
        return EntityRepo.entities.filter((x: Entity) => x.path.split('?')[0] === path)[0];
    }
}

export { Entity, EntityCategory, EntityRepo };
