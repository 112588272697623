import React from 'react';
import { Link } from 'react-router-dom';
import { replace_underscores_capitalize } from '../../helpers/string_formatters';
import { EntityDropDown } from '../navigation/UI/EntityDropDown';
import { EntityType } from '../config/Entities';

interface CatalogueContextProps {
    catalogueType: EntityType;
    catalogueParentCode: string;
    catalogueParentName: string;
}

const EntityCatalogueContext = ({
    catalogueType,
    catalogueParentCode,
    catalogueParentName,
}: CatalogueContextProps) => {
    return (
        <div className="lg:mt-8 mt-6 col-start-2 col-end-12 flex lg:flex-row flex-col justify-between lg:items-center">
            <div className="text-md lg:text-xl">
                {catalogueType === EntityType.Committee
                    ? 'Browse Parliamentary Committees'
                    : catalogueType === EntityType.InstitutionMP
                    ? 'MP Profile Dashboards'
                    : catalogueType === EntityType.Issues
                    ? 'Issue Dashboards'
                    : `Browse ${replace_underscores_capitalize(catalogueType)}`}
                {catalogueType === EntityType.Organization ? ' From ' : ''}
                {catalogueType === EntityType.Organization ? (
                    <Link
                        className="text-blue-400 font-medium hover:underline"
                        to={`/sectors/${catalogueParentCode}?name=${encodeURIComponent(
                            catalogueParentName
                        )}`}
                    >
                        {catalogueParentName}
                    </Link>
                ) : (
                    ''
                )}
                {catalogueType === EntityType.InactiveOrganization ? ' From ' : ''}
                {catalogueType === EntityType.InactiveOrganization ? (
                    <Link
                        className="text-blue-400 font-medium hover:underline"
                        to={`/sectors/${catalogueParentCode}?name=${encodeURIComponent(
                            catalogueParentName
                        )}`}
                    >
                        {catalogueParentName}
                    </Link>
                ) : (
                    ''
                )}
                {catalogueType === EntityType.Consultant && ' From '}
                {catalogueType === EntityType.Consultant && (
                    <Link
                        className="text-blue-400 font-medium hover:underline"
                        to={`/firms/${catalogueParentCode}?name=${encodeURIComponent(
                            catalogueParentName
                        )}`}
                    >
                        {catalogueParentName}
                    </Link>
                )}
                {catalogueType === EntityType.DPOH && ' From '}
                {catalogueType === EntityType.DPOH && (
                    <Link
                        className="text-blue-400 font-medium hover:underline"
                        to={`/institutions/${catalogueParentCode}?name=${encodeURIComponent(
                            catalogueParentName
                        )}`}
                    >
                        {catalogueParentName}
                    </Link>
                )}
                {catalogueType === EntityType.MP && ' From '}
                {catalogueType === EntityType.MP && (
                    <Link
                        className="text-blue-400 font-medium hover:underline"
                        to={`/committees/${catalogueParentCode}?name=${encodeURIComponent(
                            catalogueParentName
                        )}`}
                    >
                        {catalogueParentName}
                    </Link>
                )}
            </div>
            <div
                className={
                    [
                        EntityType.Organization,
                        EntityType.InactiveOrganization,
                        EntityType.Consultant,
                        EntityType.DPOH,
                        EntityType.MP,
                    ].includes(catalogueType)
                        ? 'hidden'
                        : 'hidden lg:flex'
                }
            >
                <EntityDropDown primaryText={catalogueType} />
            </div>
        </div>
    );
};

export default EntityCatalogueContext;
