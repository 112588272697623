import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from '../../../store/store';
import { entityList, entityListExtra } from '../../../interfaces/generic-entity.interface';
import { percentage_change_num } from '../../../helpers/percentage_change';

interface MpState {
    dpoh_list: entityListExtra[] | null;
    dpoh_list_loading: boolean;
}

const initialState: MpState = {
    dpoh_list: null,
    dpoh_list_loading: false,
};

const baseURL = process.env.REACT_APP_API_BASE_URL;

export const fetchMpList = createAsyncThunk(
    'mp/fetchMpList',
    async (): Promise<entityListExtra[]> => {
        const response = await axios.get(`${baseURL}/parliament/house/members`);
        const data: entityList[] = response.data.dpohs;

        const converted_data: entityListExtra[] = data.map((entry) => {
            return {
                ...entry,
                // Calculate with potential nulls replaced by 0
                change_abs: (entry.last_month || 0) - (entry.two_months_ago || 0),
                change_rel: percentage_change_num(entry.two_months_ago || 0, entry.last_month || 0),
                available: true,
                title2: entry.title2,
                affiliation: entry.affiliation ?? 'N/A',
                constituency: entry.constituency ?? 'N/A',
                province_territory: entry.province_territory ?? 'N/A',
            };
        });
        return converted_data;
    }
);

const memberOfParliamentSlice = createSlice({
    name: 'memberOfParliamentListReducer',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchMpList.pending, (state) => {
                state.dpoh_list_loading = true;
            })
            .addCase(fetchMpList.fulfilled, (state, action) => {
                state.dpoh_list_loading = false;
                state.dpoh_list = action.payload;
            })
            .addCase(fetchMpList.rejected, (state) => {
                state.dpoh_list_loading = false;
                state.dpoh_list = null;
            });
    },
});

export const selectMemberOfParliamentList = (state: RootState) => state.mpList.dpoh_list;
export const selectMemberOfParliamentListLoading = (state: RootState) =>
    state.mpList.dpoh_list_loading;

export default memberOfParliamentSlice.reducer;
