import React, { useEffect } from 'react';
import {
    fetchInstitutionList,
    selectInstitutionList,
} from '../../features/institution-dashboards/store/institutionListSlice';
import { useSelector } from 'react-redux';
import { entityListExtra } from '../../interfaces/generic-entity.interface';
import { useAppDispatch } from '../../store/store';
import ModalEntityList from './ModalEntityList';

interface ModalInstitutionListProps {
    open: boolean;
    handleClose: () => void;
}

const ModalInstitutionListDataLayer = ({ open, handleClose }: ModalInstitutionListProps) => {
    const dispatch = useAppDispatch();

    const entity_list: entityListExtra[] | null = useSelector(selectInstitutionList);

    useEffect(() => {
        if (!entity_list) dispatch(fetchInstitutionList());
    }, [dispatch, entity_list]);

    return (
        <ModalEntityList
            type="institutions"
            list={entity_list}
            // TODO: connect handleClose to modal prop
            open={open}
            handleClose={handleClose}
        />
    );
};

export default ModalInstitutionListDataLayer;
