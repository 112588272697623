import axios from 'axios';
import {
    entityOrg,
    entityOverview,
    entityOverviewRefined,
    entityOverviews,
    entityRegistration,
} from '../interfaces/generic-entity.interface';
import moment from 'moment';
import {
    consultantLinkFormatter,
    organizationLinkFormatter,
    sectorLinkFormatter,
} from '../helpers/internal_routing';

// Base URL for accessing the sector API
const baseURL = process.env.REACT_APP_API_BASE_URL;

const invertYearlyOverview = (overview: entityOverviewRefined[]) => {
    const params: (keyof entityOverviewRefined)[] = [
        'Lobbying Reports',
        'of Ministers or DMs',
        'Institutions Lobbied',
        'New Registrations',
    ];

    const output: any = [];

    params.forEach((param: keyof entityOverviewRefined, idx: number) => {
        const param_data: any = { order: idx, count: param };
        overview.forEach((entry: entityOverviewRefined) => {
            param_data[String(entry.year)] = entry[param];
        });
        output.push(param_data);
    });

    return output;
};

// Fetch an overview of the given frim / consultant
const fetchLobbyOverview = async (
    type: 'firm' | 'consultant',
    firm: string,
    date: string,
    consultant: string | null = null
) => {
    if (type === 'consultant' && !consultant) return null;

    const year = date?.split('-')[0];
    const month = date?.split('-')[1];

    const yearly_response = await axios.get(
        `${baseURL}/${type}/${firm}${
            type === 'consultant' && consultant ? `/${encodeURIComponent(consultant)}` : ''
        }/overview?monthly=false`
    );
    const yearly_data: entityOverviewRefined[] = yearly_response.data.overview
        .filter((entry: any) => +entry.year <= +year)
        .map((entry: entityOverview) => {
            return {
                ...entry,
                'Lobbying Reports': entry.count || 0,
                'of Ministers or DMs': entry.ministers || 0,
                'Institutions Lobbied': entry.institutions || 0,
                'New Registrations': entry.registrations || 0,
            };
        });

    const inverted_yearly = invertYearlyOverview(yearly_data);

    const monthly_response = await axios.get(
        `${baseURL}/${type}/${firm}${
            type === 'consultant' && consultant ? `/${encodeURIComponent(consultant)}` : ''
        }/overview?monthly=true`
    );

    const monthly_data: entityOverview[] = monthly_response.data.overview
        .filter((entry: any) => {
            return (
                +[entry.year, (entry.month + '').padStart(2, '0')].join('') <=
                +[year, month].join('')
            );
        })
        .slice(0, 18);

    const overview: entityOverviews = {
        yearly: inverted_yearly,
        monthly: monthly_data,
    };

    return overview;
};

// Fetch recent lobbying communications in a given firm / consultant
const fetchLobbyTopPartners = async (
    type: 'firm' | 'consultant',
    firm: string,
    date: string,
    consultant: string | null = null
) => {
    if (type === 'consultant' && !consultant) return null;
    const response = await axios.get(
        `${baseURL}/${type}/${encodeURIComponent(firm)}${
            type === 'consultant' && consultant ? `/${encodeURIComponent(consultant)}` : ''
        }/toppartners?date=${date}`
    );
    const data: entityOrg[] = response.data.topPartners;
    // Count the total number of comms for institutions outside of the top 6
    var other_count = 0;
    if (data.length > 6)
        other_count = data
            .slice(6)
            .map((item) => item.count)
            .reduce((sum, next) => sum + next);

    const top_six = data.slice(0, 6);
    if (other_count > 0) top_six.push({ name: 'Other', code: 'Other', count: other_count });

    return top_six;
};

// Fetch top sectors lobbied by a given firm / consultant
const fetchLobbyTopSectors = async (
    type: 'firm' | 'consultant',
    firm: string,
    date: string,
    consultant: string | null = null
) => {
    if (type === 'consultant' && !consultant) return null;
    const response = await axios.get(
        `${baseURL}/${type}/${encodeURIComponent(firm)}${
            type === 'consultant' && consultant ? `/${encodeURIComponent(consultant)}` : ''
        }/topsectors?date=${date}`
    );
    const data: entityOrg[] = response.data.topSectors;
    // Count the total number of comms for institutions outside of the top 6
    var other_count = 0;
    if (data.length > 6)
        other_count = data
            .slice(6)
            .map((item) => item.count)
            .reduce((sum, next) => sum + next);

    const top_six = data.slice(0, 6);
    if (other_count > 0) top_six.push({ name: 'Other', code: 'Other', count: other_count });

    return top_six;
};

// Fetch top institutions lobbied by a given firm / consultant
const fetchLobbyTopInstitutions = async (
    type: 'firm' | 'consultant',
    firm: string,
    date: string,
    consultant: string | null = null
) => {
    if (type === 'consultant' && !consultant) return null;
    const response = await axios.get(
        `${baseURL}/${type}/${encodeURIComponent(firm)}${
            type === 'consultant' && consultant ? `/${encodeURIComponent(consultant)}` : ''
        }/topinstitutions?date=${date}`
    );
    const data: entityOrg[] = response.data.topInstitutions;
    // Count the total number of comms for institutions outside of the top 6
    var other_count = 0;
    if (data.length > 6)
        other_count = data
            .slice(6)
            .map((item) => item.count)
            .reduce((sum, next) => sum + next);

    const top_six = data.slice(0, 6);
    if (other_count > 0) top_six.push({ name: 'Other', code: 'Other', count: other_count });

    return top_six;
};

// Fetch latest registrations for the given firm / consultant
const fetchLobbyLatestRegistrations = async (
    type: 'firm' | 'consultant',
    firm: string,
    date: string,
    consultant: string | null = null
) => {
    if (type === 'consultant' && !consultant) return null;
    const response = await axios.get(
        `${baseURL}/${type}/${encodeURIComponent(firm)}${
            type === 'consultant' && consultant ? `/${encodeURIComponent(consultant)}` : ''
        }/latestregistrations?date=${date}`
    );
    const data: entityRegistration[] = response.data.latestRegistrations.map(
        (entry: entityRegistration) => {
            return {
                ...entry,
                'Lobbying Reports (12M)': entry.count || 0,
                date: moment.utc(entry.date).format('YYYY-MM-DD'),
                client:
                    entry.sector_id && entry.sector
                        ? organizationLinkFormatter(entry.sector_id, entry.sector, entry.client)
                        : entry.client,
                sector:
                    entry.sector_id && entry.sector
                        ? sectorLinkFormatter(entry.sector_id, entry.sector)
                        : entry.sector,
                consultant:
                    entry.lobby_firm === null || entry.consultant === null
                        ? 'In-House'
                        : consultantLinkFormatter(entry.lobby_firm, entry.consultant),
                lobby_firm: entry.lobby_firm === null ? 'In-House' : entry.lobby_firm,
                history: entry.history ? 'Renewed' : 'New',
                link: `https://lobbycanada.gc.ca/app/secure/ocl/lrs/do/vwRg?regId=${entry.registration}&cno=${entry.corp}#regStart`,
                dashboard: `link:/registrations/${entry.registration}`,
            };
        }
    );
    return data;
};

// Fetch latest registrations for the given firm / consultant
const fetchLobbyLatestDeregistrations = async (
    type: 'firm' | 'consultant',
    firm: string,
    date: string,
    consultant: string | null = null
) => {
    if (type === 'consultant' && !consultant) return null;
    const response = await axios.get(
        `${baseURL}/${type}/${encodeURIComponent(firm)}${
            type === 'consultant' && consultant ? `/${encodeURIComponent(consultant)}` : ''
        }/latestderegistrations?date=${date}`
    );
    const data: entityRegistration[] = response.data.latestDeRegistrations.map(
        (entry: entityRegistration) => {
            return {
                ...entry,
                'Lobbying Reports (12M)': entry.count || 0,
                date: moment.utc(entry.date).format('YYYY-MM-DD'),
                client:
                    entry.sector_id && entry.sector
                        ? organizationLinkFormatter(entry.sector_id, entry.sector, entry.client)
                        : entry.client,
                sector:
                    entry.sector_id && entry.sector
                        ? sectorLinkFormatter(entry.sector_id, entry.sector)
                        : entry.sector,
                consultant:
                    entry.lobby_firm === null || entry.consultant === null
                        ? 'In-House'
                        : consultantLinkFormatter(entry.lobby_firm, entry.consultant),
                lobby_firm: entry.lobby_firm === null ? 'In-House' : entry.lobby_firm,
                link: `https://lobbycanada.gc.ca/app/secure/ocl/lrs/do/vwRg?regId=${entry.registration}&cno=${entry.corp}#regStart`,
                dashboard: `link:/registrations/${entry.registration}`,
            };
        }
    );
    return data;
};

export const lobbyFirmAPI = {
    fetchLobbyOverview,
    fetchLobbyTopPartners,
    fetchLobbyTopSectors,
    fetchLobbyTopInstitutions,
    fetchLobbyLatestRegistrations,
    fetchLobbyLatestDeregistrations,
};
