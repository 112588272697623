import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useAppDispatch } from '../../store/store';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    TextField,
    Fade,
    Divider,
    Autocomplete,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import LoadSpinner from '../../components/misc/LoadSpinner';
import {
    selectUserLoading,
    selectEmail,
    selectActionLoading,
    selectUserErrored,
    selectCompany,
    selectFirstName,
    selectLastName,
    applyForEmailTrial,
    EmailTrialRequest,
    TrialType,
    updateIssueEmailPreferences,
    IssuePreferencesRequest,
    signUp,
} from '../../store/reducers/userSlice';
import LoadPage from '../misc/LoadPage';
import SendIcon from '@mui/icons-material/Send';
import validator from 'validator';
import { entityListExtra } from '../../interfaces/generic-entity.interface';

import HelpIcon from '@mui/icons-material/Help';
import Modal from '../../components/misc/Modal';
import {
    fetchIssueList,
    selectIssueList,
} from '../../features/issue-dashboards/store/issueListSlice';
import logo from '../../assets/vectors/LIQ_badge.png';

interface IssueSelectionItem {
    issueId: number;
    issueTitle: string;
}

const IssueTrial = () => {
    const dispatch = useAppDispatch();

    const userLoading = useSelector(selectUserLoading);
    const userEmail = useSelector(selectEmail);

    const trialLoading = useSelector(selectActionLoading);
    const trialError = useSelector(selectUserErrored);

    const existingFirstName = useSelector(selectFirstName);
    const existingLastName = useSelector(selectLastName);
    const existingCompany = useSelector(selectCompany);

    const handleSignupRedirect = async () => {
        dispatch(signUp());
    };

    const [email, setEmail] = useState(userEmail || '');
    const [firstName, setFirstName] = useState(existingFirstName || '');
    const [lastName, setLastName] = useState(existingLastName || '');
    const [company, setCompany] = useState(existingCompany || '');
    const [jobTitle, setJobTitle] = useState(existingCompany || '');
    const [industry, setIndustry] = useState(existingCompany || '');
    const [selectedIssues, setSelectedIssues] = useState<(IssueSelectionItem | null)[]>([
        null,
        null,
        null,
    ]);

    const [emailError, setEmailError] = useState(false);
    const [firstNameError, setFirstNameError] = useState(false);
    const [lastNameError, setLastNameError] = useState(false);
    const [companyError, setCompanyError] = useState(false);
    const [jobTitleError, setJobTitleError] = useState(false);
    const [industryError, setIndustryError] = useState(false);

    const [issueModalOpen, setIssueModalOpen] = useState(false);

    const [resultModalOpen, setResultModalOpen] = React.useState(false);

    const issueList: entityListExtra[] | null = useSelector(selectIssueList);

    useEffect(() => {
        if (!issueList) dispatch(fetchIssueList());
    }, [dispatch, issueList]);

    const renderOptions = (
        props: React.HTMLAttributes<HTMLLIElement>,
        option: IssueSelectionItem
    ) => {
        return (
            <Fade in={true} key={option.issueId}>
                <li {...props} style={{ fontSize: 14 }}>
                    {option.issueTitle}
                </li>
            </Fade>
        );
    };

    const handleClickOpen = () => {
        setResultModalOpen(true);
    };

    const handleClose = () => {
        setResultModalOpen(false);
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        setEmailError(false);

        // check for required fields
        if (!validator.isEmail(email)) {
            setEmailError(true);
        }
        if (firstName === '') {
            setFirstNameError(true);
        }
        if (lastName === '') {
            setLastNameError(true);
        }
        if (company === '') {
            setCompanyError(true);
        }
        if (jobTitle === '') {
            setJobTitleError(true);
        }
        if (industry === '') {
            setIndustryError(true);
        }

        const filteredSelectedIssues: IssueSelectionItem[] = selectedIssues.filter(
            (issue) => issue !== null
        ) as IssueSelectionItem[];

        if (
            validator.isEmail(email) &&
            firstName !== '' &&
            lastName !== '' &&
            company !== '' &&
            jobTitle !== '' &&
            industry !== '' &&
            filteredSelectedIssues.length > 0
        ) {
            const trialRequest: EmailTrialRequest = {
                email,
                firstName,
                lastName,
                company,
                jobTitle,
                industry,
                trialType: TrialType.FULL,
                additionalEmails: [],
            };

            const updateIssueRequest: IssuePreferencesRequest = {
                issues: filteredSelectedIssues,
                includeEmail: true,
                firstName,
                lastName,
                isTrial: true,
            };

            handleClickOpen();

            try {
                await dispatch(applyForEmailTrial(trialRequest)).unwrap();
                await dispatch(updateIssueEmailPreferences(updateIssueRequest));
            } catch (e) {}
        } else {
            window.scrollTo(0, 0);
        }
    };

    return userLoading ? (
        <LoadPage />
    ) : userEmail ? (
        <div className="lg:p-16 lg:pt-32 pt-24 p-10 mb-16">
            <Dialog
                open={resultModalOpen}
                onClose={trialLoading ? () => {} : handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {trialLoading
                        ? 'Processing Trial Request'
                        : trialError
                        ? 'Error'
                        : 'Request Sent'}
                </DialogTitle>
                <DialogContent>
                    {trialLoading ? (
                        <div className="flex flex row justify-center items-center">
                            <LoadSpinner />
                        </div>
                    ) : (
                        <DialogContentText id="alert-dialog-description">
                            {trialError
                                ? 'Error submitting trial application. Please refresh the page and try again.'
                                : 'Thank you for applying for a LobbyIQ Committee Meeting trial. Expect to receive your first email within 24h'}
                        </DialogContentText>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} autoFocus disabled={trialLoading}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Modal
                title={'Issue Tracking'}
                open={issueModalOpen}
                width={30}
                onClose={() => setIssueModalOpen(false)}
            >
                <div>LobbyIQ automatically tracks mentions of issue keywords in Hansard, House Committees, Senate Committees, Committee Briefs, News Releases, and Lobby Registrations.</div>
            </Modal>
            <div className="flex flex-col justify-center items-center text-center mb-2">
                <div className="text-4xl font-bold mb-12">Issue Tracker Trial</div>
                <div className="text-2xl mb-3">
                    Please complete this form to receive LobbyIQ's daily issue tracker email for a
                    two-week period.
                </div>
            </div>
            <div className="flex flex-row justify-center items-center text-center mb-12 text-xl">
                <div>Read more about</div>
                <Link
                    className="text-sky-600 hover:underline underline-offset-2 ml-2"
                    to={'/features/tracking'}
                >
                    Issue Tracking
                </Link>
            </div>
            <form autoComplete="off" onSubmit={handleSubmit}>
                <div className="flex lg:flex-row md:flex-row flex-col justify-center items-center lg:pb-8 pb-6">
                    <div className="mb-5 lg:mb-0 md:mb-0">
                        <div className="p-3">Business Email</div>
                        <div className="lg:w-96 w-72 lg:pr-4 md:pr-4">
                            <TextField
                                fullWidth
                                id="email"
                                placeholder="Business Email"
                                variant="outlined"
                                required
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                                error={emailError}
                                {...(emailError ? { label: 'Invalid email' } : {})}
                            />
                        </div>
                    </div>
                    <div>
                        <div className="lg:pl-6 md:pl-6 p-3">Company/Organization</div>
                        <div className="lg:w-96 w-72 lg:pl-4 md:pl-4">
                            <TextField
                                fullWidth
                                id="comp-org"
                                placeholder="Company/Organization"
                                variant="outlined"
                                required
                                onChange={(e) => setCompany(e.target.value)}
                                value={company}
                                error={companyError}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex lg:flex-row md:flex-row flex-col justify-center items-center lg:pb-12 pb-8">
                    <div className="mb-5 lg:mb-0 md:mb-0">
                        <div className="p-3">First Name</div>
                        <div className="lg:w-96 w-72 lg:pr-4 md:pr-4">
                            <TextField
                                fullWidth
                                id="first-name"
                                placeholder="First Name"
                                variant="outlined"
                                required
                                onChange={(e) => setFirstName(e.target.value)}
                                value={firstName}
                                error={firstNameError}
                            />
                        </div>
                    </div>
                    <div>
                        <div className="lg:pl-6 md:pl-6 p-3">Last Name</div>
                        <div className="lg:w-96 w-72 lg:pl-4 md:pl-4">
                            <TextField
                                fullWidth
                                id="last-name"
                                placeholder="Last Name"
                                variant="outlined"
                                required
                                onChange={(e) => setLastName(e.target.value)}
                                value={lastName}
                                error={lastNameError}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex lg:flex-row md:flex-row flex-col justify-center items-center lg:pb-12 pb-8">
                    <div className="mb-5 lg:mb-0 md:mb-0">
                        <div className="p-3">Job Title</div>
                        <div className="lg:w-96 w-72 lg:pr-4 md:pr-4">
                            <TextField
                                fullWidth
                                id="job-title"
                                placeholder="Job Title"
                                variant="outlined"
                                required
                                onChange={(e) => setJobTitle(e.target.value)}
                                value={jobTitle}
                                error={jobTitleError}
                            />
                        </div>
                    </div>
                    <div>
                        <div className="lg:pl-6 md:pl-6 p-3">Industry</div>
                        <div className="lg:w-96 w-72 lg:pl-4 md:pl-4">
                            <TextField
                                fullWidth
                                id="industry"
                                placeholder="Industry"
                                variant="outlined"
                                required
                                onChange={(e) => setIndustry(e.target.value)}
                                value={industry}
                                error={industryError}
                            />
                        </div>
                    </div>
                </div>
                <Divider sx={{ marginBottom: 3 }} />
                <div className="grid grid-cols-12 justify-center items-center md:h-[100px]">
                    <div className="flex flex-row text-center p-2 mx-auto col-span-12 text-xl font-bold mb-5">
                        <div
                            className="ml-2 mr-6 hover:cursor-pointer"
                            onClick={() => setIssueModalOpen(true)}
                        >
                            <HelpIcon color="info" />
                        </div>
                        Select three Issues to track
                    </div>
                    <div className="flex flex-col md:flex-row justify-between col-span-8 md:col-start-3 mb-5 md:mb-0">
                        {[...Array(3)].map((_, idx) => (
                            <div className="px-5 md:w-96 w-64 mb-5 md:mb-0">
                                <Autocomplete
                                    isOptionEqualToValue={(
                                        option: IssueSelectionItem,
                                        value: IssueSelectionItem
                                    ) => option.issueId === value.issueId}
                                    fullWidth
                                    disableListWrap
                                    size="small"
                                    value={selectedIssues[idx] || null}
                                    onChange={(e, newValue) => {
                                        const newIssues = selectedIssues.map((existingValue, i) => {
                                            if (i === idx) {
                                                // replace the intended value
                                                return newValue;
                                            } else {
                                                // The rest haven't changed
                                                return existingValue;
                                            }
                                        });
                                        setSelectedIssues(newIssues);
                                    }}
                                    renderInput={(params) => (
                                        <TextField {...params} label={`Issue ${idx + 1}`} />
                                    )}
                                    options={
                                        issueList?.map((issue) => ({
                                            issueId: issue.code,
                                            issueTitle: issue.title,
                                        })) || []
                                    }
                                    getOptionLabel={(option: IssueSelectionItem) =>
                                        option.issueTitle ?? ''
                                    }
                                    renderOption={renderOptions}
                                />
                            </div>
                        ))}
                    </div>
                </div>
                <div className="flex flex-row justify-center items-center mt-12">
                    <Button
                        className="bg-slate-600 w-72"
                        variant="contained"
                        size="large"
                        type="submit"
                        endIcon={<SendIcon />}
                    >
                        <div className="font-sans not-italic font-bold text-normal">Request</div>
                    </Button>
                </div>
            </form>
        </div>
    ) : (
        <div className="flex flex-col sm:flex-row items-center justify-center text-2xl py-[50vh]">
            <img src={logo} className="h-32" alt="logo" />
            <div style={{ marginLeft: '20px', marginRight: '20px' }} />
            <div className="flex flex-col mx-10">
                <div className="flex flex-col items-center justify-center text-center">
                    <div className="flex flex-row">
                        Please
                        <div
                            className="flex shrink-0 mx-2 text-sky-600 font-bold hover:underline hover:cursor-pointer"
                            onClick={handleSignupRedirect}
                        >
                            sign up
                        </div>
                    </div>
                    to register for the Issue Tracker free trial
                </div>
                <div className="flex flex-row justify-center items-center text-center mt-6 text-2xl">
                    <div>
                        Read more about
                        <Link
                            className="text-sky-600 hover:underline underline-offset-2 ml-2"
                            to={'/features/tracking'}
                        >
                            Issue Tracking
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default IssueTrial;
