import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useAppDispatch } from '../../store/store';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    TextField,
    Autocomplete,
    Fade,
    Divider,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import LoadSpinner from '../../components/misc/LoadSpinner';
import {
    selectUserLoading,
    selectEmail,
    selectActionLoading,
    selectUserErrored,
    selectCompany,
    selectFirstName,
    selectLastName,
    applyForEmailTrial,
    EmailTrialRequest,
    TrialType,
} from '../../store/reducers/userSlice';
import LoadPage from '../misc/LoadPage';
import SendIcon from '@mui/icons-material/Send';
import validator from 'validator';
import { entityListExtra } from '../../interfaces/generic-entity.interface';
import {
    CommitteeListItem,
    fetchSenateAndHouseCommitteeList,
    selectCombinedCommitteeList,
} from '../../features/committee-dashboards/data/committeeListSlice';

import HelpIcon from '@mui/icons-material/Help';
import Modal from '../../components/misc/Modal';

const CommitteeTrial = () => {
    const dispatch = useAppDispatch();

    const userLoading = useSelector(selectUserLoading);
    const userEmail = useSelector(selectEmail);

    const trialLoading = useSelector(selectActionLoading);
    const trialError = useSelector(selectUserErrored);

    const existingFirstName = useSelector(selectFirstName);
    const existingLastName = useSelector(selectLastName);
    const existingCompany = useSelector(selectCompany);

    const [email, setEmail] = useState(userEmail || '');
    const [firstName, setFirstName] = useState(existingFirstName || '');
    const [lastName, setLastName] = useState(existingLastName || '');
    const [company, setCompany] = useState(existingCompany || '');
    const [jobTitle, setJobTitle] = useState(existingCompany || '');
    const [industry, setIndustry] = useState(existingCompany || '');
    const [committeeMeetings, setCommitteeMeetings] = useState<any[]>([null, null, null]);

    const [emailError, setEmailError] = useState(false);
    const [firstNameError, setFirstNameError] = useState(false);
    const [lastNameError, setLastNameError] = useState(false);
    const [companyError, setCompanyError] = useState(false);
    const [jobTitleError, setJobTitleError] = useState(false);
    const [industryError, setIndustryError] = useState(false);

    const [committeeModalOpen, setCommitteeModalOpen] = useState(false);

    const [resultModalOpen, setResultModalOpen] = React.useState(false);

    const committeeList: CommitteeListItem[] | null = useSelector(selectCombinedCommitteeList);

    useEffect(() => {
        if (!committeeList) dispatch(fetchSenateAndHouseCommitteeList());
    }, [dispatch, committeeList]);

    const renderOptions = (
        props: React.HTMLAttributes<HTMLLIElement>,
        option: entityListExtra | CommitteeListItem
    ) => {
        return (
            <Fade in={true} key={option.code || option.title}>
                <li {...props} style={{ fontSize: 14 }}>
                    {option.title}
                </li>
            </Fade>
        );
    };

    const handleClickOpen = () => {
        setResultModalOpen(true);
    };

    const handleClose = () => {
        setResultModalOpen(false);
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        setEmailError(false);

        // check for required fields
        if (!validator.isEmail(email)) {
            setEmailError(true);
        }
        if (firstName === '') {
            setFirstNameError(true);
        }
        if (lastName === '') {
            setLastNameError(true);
        }
        if (company === '') {
            setCompanyError(true);
        }
        if (jobTitle === '') {
            setJobTitleError(true);
        }
        if (industry === '') {
            setIndustryError(true);
        }

        if (
            validator.isEmail(email) &&
            firstName !== '' &&
            lastName !== '' &&
            company !== '' &&
            jobTitle !== '' &&
            industry !== ''
        ) {
            const trialRequest: EmailTrialRequest = {
                email,
                firstName,
                lastName,
                company,
                jobTitle,
                industry,
                trialType: TrialType.COMMITTEE,
                additionalEmails: [],
                // selections rely on order, so null is passed to maintain length
                committeeMeetings: committeeMeetings.filter((committee) => committee !== null),
                stakeholderSectors: [],
                organizations: [],
                institutions: [],
                dpohs: [],
                keywordAlerts: [], // keywordAlerts.filter(keyword => keyword !== ''),
                grInsider: false, // CURRENTLY DISABLED grInsider ? true : false
                questionPeriod: false, // CURRENTLY DISABLED questionPeriod ? true : false
            };

            handleClickOpen();

            try {
                await dispatch(applyForEmailTrial(trialRequest)).unwrap();
            } catch (e) {}
        } else {
            window.scrollTo(0, 0);
        }
    };

    return userLoading ? (
        <LoadPage />
    ) : (
        <div className="lg:p-16 lg:pt-32 pt-24 p-10 mb-16">
            <Dialog
                open={resultModalOpen}
                onClose={trialLoading ? () => {} : handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {trialLoading
                        ? 'Processing Trial Request'
                        : trialError
                        ? 'Error'
                        : 'Request Sent'}
                </DialogTitle>
                <DialogContent>
                    {trialLoading ? (
                        <div className="flex flex row justify-center items-center">
                            <LoadSpinner />
                        </div>
                    ) : (
                        <DialogContentText id="alert-dialog-description">
                            {trialError
                                ? 'Error submitting trial application. Please refresh the page and try again.'
                                : 'Thank you for applying for a LobbyIQ Committee Meeting trial.'}
                        </DialogContentText>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} autoFocus disabled={trialLoading}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Modal
                title={'Committee Summaries'}
                open={committeeModalOpen}
                width={30}
                onClose={() => setCommitteeModalOpen(false)}
            >
                <div>
                    Three-page summary and transcript of Committee meeting delivered within an hour
                    of meeting conclusion.
                </div>
                <div>When selecting committees, Senate Committees are prefixed with "S-"</div>
            </Modal>
            <div className="flex flex-col text-center text-4xl font-bold mb-12">
                Committee Monitoring Free Trial
            </div>
            <div className="flex flex-col justify-center items-center text-center mb-3">
                <div className="text-2xl">
                    Please complete this form to receive LobbyIQ's instant Committee meeting
                    transcripts and summaries by email for a two-week period.
                </div>
            </div>
            <div className="flex md:flex-row flex-col justify-center items-center text-center mb-12 text-xl">
                <div>Read more about</div>
                <Link
                    className="text-sky-600 hover:underline underline-offset-2 ml-2"
                    to={'/features/monitor'}
                >
                    Committee Monitoring
                </Link>
            </div>
            <form autoComplete="off" onSubmit={handleSubmit}>
                <div className="flex lg:flex-row md:flex-row flex-col justify-center items-center lg:pb-8 pb-6">
                    <div className="mb-5 lg:mb-0 md:mb-0">
                        <div className="p-3">Business Email</div>
                        <div className="lg:w-96 w-72 lg:pr-4 md:pr-4">
                            <TextField
                                fullWidth
                                id="email"
                                placeholder="Business Email"
                                variant="outlined"
                                required
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                                error={emailError}
                                {...(emailError ? { label: 'Invalid email' } : {})}
                            />
                        </div>
                    </div>
                    <div>
                        <div className="lg:pl-6 md:pl-6 p-3">Company/Organization</div>
                        <div className="lg:w-96 w-72 lg:pl-4 md:pl-4">
                            <TextField
                                fullWidth
                                id="comp-org"
                                placeholder="Company/Organization"
                                variant="outlined"
                                required
                                onChange={(e) => setCompany(e.target.value)}
                                value={company}
                                error={companyError}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex lg:flex-row md:flex-row flex-col justify-center items-center lg:pb-12 pb-8">
                    <div className="mb-5 lg:mb-0 md:mb-0">
                        <div className="p-3">First Name</div>
                        <div className="lg:w-96 w-72 lg:pr-4 md:pr-4">
                            <TextField
                                fullWidth
                                id="first-name"
                                placeholder="First Name"
                                variant="outlined"
                                required
                                onChange={(e) => setFirstName(e.target.value)}
                                value={firstName}
                                error={firstNameError}
                            />
                        </div>
                    </div>
                    <div>
                        <div className="lg:pl-6 md:pl-6 p-3">Last Name</div>
                        <div className="lg:w-96 w-72 lg:pl-4 md:pl-4">
                            <TextField
                                fullWidth
                                id="last-name"
                                placeholder="Last Name"
                                variant="outlined"
                                required
                                onChange={(e) => setLastName(e.target.value)}
                                value={lastName}
                                error={lastNameError}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex lg:flex-row md:flex-row flex-col justify-center items-center lg:pb-12 pb-8">
                    <div className="mb-5 lg:mb-0 md:mb-0">
                        <div className="p-3">Job Title</div>
                        <div className="lg:w-96 w-72 lg:pr-4 md:pr-4">
                            <TextField
                                fullWidth
                                id="job-title"
                                placeholder="Job Title"
                                variant="outlined"
                                required
                                onChange={(e) => setJobTitle(e.target.value)}
                                value={jobTitle}
                                error={jobTitleError}
                            />
                        </div>
                    </div>
                    <div>
                        <div className="lg:pl-6 md:pl-6 p-3">Industry</div>
                        <div className="lg:w-96 w-72 lg:pl-4 md:pl-4">
                            <TextField
                                fullWidth
                                id="industry"
                                placeholder="Industry"
                                variant="outlined"
                                required
                                onChange={(e) => setIndustry(e.target.value)}
                                value={industry}
                                error={industryError}
                            />
                        </div>
                    </div>
                </div>
                <Divider sx={{ marginBottom: 3 }} />
                <div className="grid grid-cols-12 md:h-[100px]">
                    <div className="flex flex-row justify-start p-2 mx-auto col-span-12 text-xl font-bold">
                        <div
                            className="ml-2 mr-6 hover:cursor-pointer"
                            onClick={() => setCommitteeModalOpen(true)}
                        >
                            <HelpIcon color="info" />
                        </div>
                        Please select three House or Senate Committees
                    </div>

                    <div className="flex flex-col md:flex-row justify-between mt-6 col-span-8 md:col-start-3 col-start-0 md:mb-0">
                        {[...Array(3)].map((_, idx) => (
                            <div className="px-5 md:w-96 w-72 mb-5 md:mb-0">
                                <Autocomplete
                                    isOptionEqualToValue={(
                                        option: CommitteeListItem,
                                        value: CommitteeListItem
                                    ) => option.title === value.title}
                                    fullWidth
                                    disableListWrap
                                    size="small"
                                    value={committeeMeetings[idx] || ''}
                                    onChange={(e, newValue) => {
                                        const newCommittees = committeeMeetings.map(
                                            (existingValue, i) => {
                                                if (i === idx) {
                                                    // replace the intended value
                                                    return newValue;
                                                } else {
                                                    // The rest haven't changed
                                                    return existingValue;
                                                }
                                            }
                                        );
                                        setCommitteeMeetings(newCommittees);
                                    }}
                                    renderInput={(params) => (
                                        <TextField {...params} label={`Committee ${idx + 1}`} />
                                    )}
                                    options={committeeList || []}
                                    getOptionLabel={(option: any) => option.title ?? ''}
                                    renderOption={renderOptions}
                                />
                            </div>
                        ))}
                    </div>
                </div>
                <div className="flex flex-row justify-center items-center mt-12">
                    <Button
                        className="bg-slate-600 w-72"
                        variant="contained"
                        size="large"
                        type="submit"
                        endIcon={<SendIcon />}
                    >
                        <div className="font-sans not-italic font-bold text-normal">Request</div>
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default CommitteeTrial;
