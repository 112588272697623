import React from 'react';
import logo from '../../assets/vectors/LIQ_badge.png';

/*
    Component: TempPage.tsx
    Params: text - the text to be displayed on the temporary page
    Author: Will Brewer
    Desc:
    Simple temporary placeholder apge for undeveloped routes. Will be incrementally removed and eventually deleted 
*/
export const TempPage = (props: { text: string }) => {
    return (
        <div>
            <div className="flex flex-row items-center justify-center py-[50vh] text-xl">
                <img src={logo} className="h-24" alt="logo" />
                <div style={{ marginLeft: '20px', marginRight: '20px' }} />
                <p>{props.text}</p>
            </div>
        </div>
    );
};
