import React from 'react';
import Chart from '@mui/icons-material/BarChartRounded';
import { months } from '../../constants/date_time';
import {
    BarChart as ReBarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    Cell,
    ReferenceLine,
} from 'recharts';

// Title is an optional parameters with default specified in function signature below.
interface barChartProps {
    title?: string;
    subtitle?: string;
    primaryColor?: string;
    secondaryColor?: string;
    data: any[];
    dataKey: string;
    xKey: string;
    yKey: string;
    mini?: boolean;
    average?: boolean;
}

const renderCustomizedXAxisTick = ({ x, y, payload }: any) => {
    return (
        <text
            className="text-sm font-medium"
            x={x}
            y={y + 10}
            fill="#475569"
            textAnchor="middle"
            dominantBaseline="central"
        >
            {months[payload.value - 1].slice(0, 1)}
        </text>
    );
};

const renderCustomizedYAxisTick = ({ x, y, payload }: any) => {
    return (
        <text
            className="text-sm font-medium"
            x={x - 15}
            y={y}
            fill="#475569"
            textAnchor="middle"
            dominantBaseline="central"
        >
            {payload.value}
        </text>
    );
};

const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
        return (
            <div className="bg-white p-3 rounded-xl shadow-lg border border-1 border-slate-100">
                <p>{`${months[label - 1]}`}</p>
                <p>{`${payload[0].value} Comms`}</p>
            </div>
        );
    }
    return null;
};

/*
    Component: BarChart.tsx
    Params:
        title - the title for the top of the tile.
        primary_color - the primary color of historical data from the previous year.
        secondary_color - the primary color of historical data from the current year.
        data - Data to be displayed
        dataKey - key in the data type to be used for bars
        xKey - key in the data type to be used for x axis
        yKey - key in the data type to be used for y axis
        mini - determines regular or reduced component height
    Author: William Brewer
    Desc: Regular scale bar chart for clusters to display monthly data.
*/
const BarChart = ({
    title = 'Title',
    subtitle = '',
    primaryColor = '#8884d8',
    secondaryColor = '#8884d8',
    data,
    dataKey,
    xKey,
    yKey,
    mini,
    average = false,
}: barChartProps) => {
    let new_year = -1;
    for (let i = 1; i < data.length; i++) {
        if (
            (data[i].month === 1 || data[i].mn === 1) &&
            (data[i].year !== data[i - 1].yr || data[i].yr !== data[i - 1].yr)
        ) {
            new_year = i;
        }
    }

    const avg = Math.round(data.reduce((sum, next) => sum + next[dataKey], 0) / data.length);

    return (
        <div className={`${mini ? 'h-48' : 'h-64'} bg-white shadow-md rounded-md p-4`}>
            <div className="text-md font-medium flex flex-row items-center space-x-2">
                <Chart fontSize="small" color="success" />
                <div className="overflow-x-hidden whitespace-nowrap text-ellipsis">{title}</div>
            </div>
            <div className="h-5 text-sm overflow-x-hidden whitespace-nowrap text-ellipsis">
                {subtitle}
            </div>
            <ResponsiveContainer width="100%" height="100%">
                <ReBarChart
                    data={data}
                    margin={{
                        top: 10,
                        right: 0,
                        bottom: 35,
                        left: -20,
                    }}
                >
                    <CartesianGrid strokeDasharray="5" stroke="#e2e8f0" vertical={false} />
                    <XAxis dataKey={xKey} interval={0} tick={renderCustomizedXAxisTick} />
                    <YAxis dataKey={yKey} tick={renderCustomizedYAxisTick} allowDecimals={false} />
                    <Tooltip content={CustomTooltip} />
                    <Bar dataKey={dataKey} radius={[5, 5, 0, 0]} isAnimationActive={false}>
                        {data.map((entry, index) => (
                            <Cell
                                key={`cell-${index}`}
                                fill={index >= new_year ? primaryColor : secondaryColor}
                            />
                        ))}
                    </Bar>
                    {average && (
                        <text
                            x="100%"
                            y="15%"
                            style={{
                                fontSize: 14,
                                fontWeight: 'bold',
                                fill: '#475569',
                            }}
                            width={200}
                            textAnchor="end"
                        >
                            Avg: {avg}
                        </text>
                    )}
                    {average && (
                        <ReferenceLine
                            strokeDasharray="5"
                            strokeWidth={2}
                            y={avg}
                            stroke="#475569"
                        />
                    )}
                </ReBarChart>
            </ResponsiveContainer>
        </div>
    );
};

export default BarChart;
