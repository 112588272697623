import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import { entityListExtra } from '../../interfaces/generic-entity.interface';
import Modal from '../../components/misc/Modal';
import { replace_underscores_capitalize } from '../../helpers/string_formatters';
import EntityListSearch from '../lists/EntityListSearch';

import { Alert, Button, Grow, IconButton, Snackbar, Step, StepLabel, Stepper } from '@mui/material';

import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { useAppDispatch } from '../../store/store';
import {
    updateCommitteesSelections,
    updateInstitutionsSelections,
    updateSectorsSelections,
    Selection,
    selectSelectionLoading,
    updateNewsletterSelections,
} from '../../store/reducers/customSelectionSlice';
import { useSelector } from 'react-redux';
import LoadSpinner from '../../components/misc/LoadSpinner';
import { CommitteeListItem } from '../../features/committee-dashboards/data/committeeListSlice';

interface selectionModalProps {
    availableList: entityListExtra[] | CommitteeListItem[] | null;
    ownedList: Selection[];
    maxEntitlements: number;
    type: 'sectors' | 'institutions' | 'committees' | 'newsletters';
    open: boolean;
    handleClose: () => void;
}

const SelectionModal = ({
    type,
    availableList,
    ownedList,
    maxEntitlements,
    open,
    handleClose,
}: selectionModalProps) => {
    const dispatch = useAppDispatch();

    const loading = useSelector(selectSelectionLoading);

    const [activeStep, setActiveStep] = React.useState(0);

    const [maxAlertOpen, setMaxAlertOpen] = useState<boolean>(false);
    const [collisionAlertOpen, setCollisionAlertOpen] = useState<boolean>(false);
    const [selected, setSelected] = useState<Selection[]>([]);

    useEffect(() => {
        setSelected([...ownedList]);
        setCollisionAlertOpen(false);
        setActiveStep(0);
    }, [ownedList, type])

    const handleSelect = (selected_entity: Selection) => {
        const code = selected.find((item) => item.code === selected_entity.code);
        if (selected.length === maxEntitlements) {
            setMaxAlertOpen(true);
        } else if (code) {
            setCollisionAlertOpen(true);
        } else {
            setSelected([
                ...selected,
                {
                    code: selected_entity.code.toString(),
                    title: selected_entity.title,
                },
            ]);
        }
    };

    const handleRemove = (selected_entity: Selection) => {
        setSelected(selected.filter((item) => item.code !== selected_entity.code));
    };

    const filteredOptions = () => {
        return availableList?.filter(
            (option: entityListExtra | CommitteeListItem) => !selected.find((selection) => selection.title === option.title)
        );
    };

    const handleAlertClose = (event: React.SyntheticEvent | Event, reason: string) => {
        if (reason !== 'clickaway') {
            setMaxAlertOpen(false);
            setCollisionAlertOpen(false);
        }
    };

    const handleNext = async () => {
        if (activeStep === 0) {
            if (selected.length > 0 || type === 'newsletters')
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else if (activeStep === 1) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else {
            if (type === 'sectors') {
                await dispatch(updateSectorsSelections(selected)).unwrap();
            } else if (type === 'committees') {
                await dispatch(updateCommitteesSelections(selected)).unwrap();
            } else if (type === 'institutions') {
                await dispatch(updateInstitutionsSelections(selected)).unwrap();
            } else if (type === 'newsletters') {
                await dispatch(updateNewsletterSelections(selected)).unwrap();
            }
            handleClose();
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            title={`Browse Available ${replace_underscores_capitalize(type)}`}
        >
            <Snackbar
                open={collisionAlertOpen}
                autoHideDuration={3000}
                onClose={(event: React.SyntheticEvent | Event, reason: string) =>
                    handleAlertClose(event, reason)
                }
                anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
            >
                <Alert severity="error" sx={{ width: '100%' }}>
                    Already selected
                </Alert>
            </Snackbar>

            <Snackbar
                open={maxAlertOpen}
                autoHideDuration={3000}
                onClose={(event: React.SyntheticEvent | Event, reason: string) =>
                    handleAlertClose(event, reason)
                }
                anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
            >
                <Alert severity="error" sx={{ width: '100%' }}>
                    Max selections reached
                </Alert>
            </Snackbar>

            <div className="max-h-[75vh] overflow-y-auto">
                <div className="p-3">
                    <Stepper activeStep={activeStep}>
                        <Step>
                            <StepLabel>Make Selections</StepLabel>
                        </Step>
                        <Step>
                            <StepLabel
                                error={
                                    selected.length === 0 && type !== 'newsletters' ? true : false
                                }
                            >
                                <div>Review</div>
                                <Grow
                                    in={selected.length === 0 && type !== 'newsletters'}
                                    className="italic"
                                    unmountOnExit
                                >
                                    <div>Select a {type.slice(0, -1)} before proceeding</div>
                                </Grow>
                            </StepLabel>
                        </Step>
                        <Step>
                            <StepLabel error={selected.length === 0 ? true : false}>
                                Confirm
                            </StepLabel>
                        </Step>
                    </Stepper>
                </div>

                <div className="p-2 flex flex-row justify-between">
                    <Button
                        variant="outlined"
                        color="inherit"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        startIcon={<ChevronLeftRoundedIcon />}
                    >
                        Back
                    </Button>
                    {loading ? (
                        <LoadSpinner />
                    ) : (
                        <Button
                            variant="outlined"
                            onClick={handleNext}
                            disabled={
                                (selected.length === 0 && type !== 'newsletters') ||
                                selected === ownedList
                            }
                            endIcon={
                                activeStep === 2 ? <SaveRoundedIcon /> : <ChevronRightRoundedIcon />
                            }
                        >
                            {activeStep === 2 ? 'Save' : 'Next'}
                        </Button>
                    )}
                </div>

                {activeStep === 0 ? (
                    <div className="p-4">
                        <div className="p-2 flex flex-row justify-between">
                            <div className="text-slate-500 font-bold">
                                Please select {maxEntitlements} {type} below
                            </div>
                            <Link
                                className="text-blue-600 underline hover:underline-offset-2"
                                to={`/${type === 'newsletters' ? 'sectors' : type}`}
                                target="_blank"
                            >
                                All available {type}
                            </Link>
                        </div>
                        <EntityListSearch
                            type={type === 'newsletters' ? 'sectors' : type}
                            list={filteredOptions() || []}
                            disableLinks
                            onClick={handleSelect}
                            endIcon={<AddRoundedIcon fontSize="small" />}
                        />
                        <div className="p-2 mt-8 text-slate-500">
                            {maxEntitlements - selected.length} selection(s) remaining
                        </div>
                        <hr className="h-0.5 my-3 bg-slate-100 rounded" />
                        {selected.map((entity) => (
                            <Grow in={true}>
                                <div className="flex flex-row justify-between items-center bg-white shadow-sm rounded-md px-3 py-2 my-2 text-sm text-slate-500 font-medium">
                                    <div>
                                        {entity.code} - {entity.title}
                                    </div>
                                    <IconButton
                                        color="error"
                                        size="small"
                                        onClick={() => handleRemove(entity)}
                                    >
                                        <DeleteForeverRoundedIcon fontSize="small" />
                                    </IconButton>
                                </div>
                            </Grow>
                        ))}
                        {[...Array(Math.max(0, maxEntitlements - selected.length))].map(() => (
                            <Grow in={true}>
                                <div className="border border-1 border-slate-300 rounded-md p-3 my-2 text-sm text-slate-300 font-medium">
                                    Add another {type.slice(0, -1)}
                                </div>
                            </Grow>
                        ))}
                    </div>
                ) : activeStep === 1 ? (
                    <div className="p-4">
                        <div className="p-2 font-bold text-slate-500">Selected {type}:</div>
                        <hr className="h-0.5 my-3 bg-slate-100 rounded" />
                        {selected.map((selection: Selection) =>
                            ownedList.find((item) => item.code === selection.code) ? (
                                <Grow in={true}>
                                    <div className="flex flex-row justify-between bg-white shadow-sm rounded-md p-3 my-2 text-sm text-slate-500 font-medium">
                                        <div>
                                            {selection.code} - {selection.title}
                                        </div>
                                        <div className="italic font-bold text-white bg-slate-600 px-2 rounded-md">
                                            Access unchanged
                                        </div>
                                    </div>
                                </Grow>
                            ) : (
                                <Grow in={true}>
                                    <div className="flex flex-row justify-between bg-white shadow-sm rounded-md p-3 my-2 text-sm text-slate-500 font-medium">
                                        <div className="text-liq-green">
                                            {selection.code} - {selection.title}
                                        </div>
                                        <div className="italic font-bold text-white bg-liq-green px-2 rounded-md">
                                            Access granted
                                        </div>
                                    </div>
                                </Grow>
                            )
                        )}
                        {ownedList.map((entity: Selection) =>
                            selected.find((item) => item.code === entity.code) ? (
                                <></>
                            ) : (
                                <Grow in={true}>
                                    <div className="flex flex-row justify-between bg-white shadow-sm rounded-md p-3 my-2 text-sm text-slate-500 font-medium">
                                        <div className="text-liq-red">
                                            {entity.code} - {entity.title}
                                        </div>
                                        <div className="italic font-bold text-white bg-liq-red px-2 rounded-md">
                                            Access removed
                                        </div>
                                    </div>
                                </Grow>
                            )
                        )}
                    </div>
                ) : (
                    <div className="p-4">
                        <div className="flex flex-col mb-4">
                            <div className="p-2 text-slate-500 my-3">
                                <b>Please review</b> your {type.slice(0, -1)} selections then click
                                Save.
                            </div>
                            {selected.map((entity: Selection) => (
                                <Grow in={true}>
                                    <div className=" bg-white shadow-sm rounded-md p-2 my-1 text-sm text-slate-500 font-medium">
                                        <div>
                                            {entity.code} - {entity.title}
                                        </div>
                                    </div>
                                </Grow>
                            ))}
                            <div className="p-2 text-slate-500 mt-3 italic">
                                Note: for 48 hours after logging in for the first time, you will be
                                able to change your dashboard selections. After 48 hours, dashboard
                                changes are permitted only once per month for each type.
                            </div>
                        </div>
                        <Button
                            fullWidth
                            variant="contained"
                            onClick={handleNext}
                            disabled={selected.length === 0 || selected === ownedList || loading}
                            endIcon={<SaveRoundedIcon />}
                        >
                            Save
                        </Button>
                    </div>
                )}
            </div>
        </Modal>
    );
};

export default SelectionModal;
