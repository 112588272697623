import React from 'react';
import { mpBiography, mpCommitteePosition } from '../interface/dpoh-entity.interface';
import { Fade, Grow } from '@mui/material';
import LoadingTable from '../../../components/generic-dashboards/loading/LoadingTable';
import { MissingData } from '../../../components/misc/MissingData';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';

const CommitteeItem = ({
    cmte,
    cmteMap,
    type,
}: {
    cmte: string;
    cmteMap: Map<any, any>;
    type: string;
}) => {
    return type === 'mp' ? (
        <li className="text-sm list-inside list-disc">
            {cmte} (parl{' '}
            {cmteMap
                .get(cmte)
                .sort((a: number, b: number) => b - a)
                .map((parliament: number, i: number) => (
                    <span key={i}>
                        {i === 0 ? '' : ' '}
                        {parliament}
                    </span>
                ))}
            )
        </li>
    ) : (
        <li className="text-sm list-inside list-disc">{cmte}</li>
    );
};

const MPBioInfo = ({
    mpName,
    bioData,
    loading,
    type = 'mp',
}: {
    mpName: string;
    bioData: mpBiography | null;
    loading: boolean;
    type?: 'mp' | 'senator';
}) => {
    const latestCommittee =
        bioData && type === 'mp'
            ? [...bioData.committees].sort(
                  (a: mpCommitteePosition, b: mpCommitteePosition) => b.parliament! - a.parliament!
              )[0]
            : null;

    let committees: Map<any, any>;

    const uniqueCmtes = (positions: mpCommitteePosition[]) => {
        committees = new Map();
        positions.forEach((x: mpCommitteePosition) => {
            if (committees.has(x.cmtename)) committees.get(x.cmtename).push(x.parliament);
            else committees.set(x.cmtename, [x.parliament]);
        });
        return Array.from(committees.keys());
    };

    const displayData: string[] = bioData ? uniqueCmtes(bioData.committees) : [];

    return (
        <Fade in={true}>
            <div>
                {loading ? (
                    <LoadingTable />
                ) : !loading && !bioData ? (
                    <MissingData />
                ) : bioData ? (
                    <Grow in={true}>
                        <div className="bg-white p-3 flex lg:flex-row flex-col justify-between lg:items-start shadow-md rounded-md">
                            <div className="flex flex-col mb-4 mr-4">
                                <div className="text-lg font-medium">
                                    {type === 'mp' ? 'MP' : 'Senator'} {mpName}
                                </div>
                                <hr className="h-0.5 my-3 bg-slate-100 rounded" />
                                <div className="text-md font-normal">
                                    {latestCommittee
                                        ? latestCommittee.cmtename + ` (${latestCommittee.cmteid})`
                                        : ''}
                                </div>
                                <div className="text-md font-normal">
                                    {bioData.bio.affiliation} -{' '}
                                    {bioData.bio.constituency ? bioData.bio.constituency + ',' : ''}{' '}
                                    {bioData.bio.province_territory}
                                </div>
                                <div className="flex flex-row mt-2 gap-2">
                                    {bioData.bio.twitter_handle ? (
                                        <a
                                            className="text-blue-500 hover:underline underline-offset-2"
                                            href={
                                                bioData.bio.twitter_handle.startsWith('https://')
                                                    ? `https://www.twitter.com/${bioData.bio.twitter_handle}`
                                                    : bioData.bio.twitter_handle
                                            }
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <TwitterIcon />
                                        </a>
                                    ) : (
                                        <span className="text-slate-200">
                                            <TwitterIcon fontSize="small" />
                                        </span>
                                    )}
                                    {bioData.bio.facebook_handle ? (
                                        <a
                                            className="text-blue-700 hover:underline underline-offset-2"
                                            href={
                                                bioData.bio.facebook_handle.startsWith('https://')
                                                    ? `https://www.facebook.com/${bioData.bio.facebook_handle}`
                                                    : bioData.bio.facebook_handle
                                            }
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <FacebookIcon />
                                        </a>
                                    ) : (
                                        <span className="text-slate-200">
                                            <FacebookIcon />
                                        </span>
                                    )}
                                </div>
                            </div>
                            {bioData.committees.length > 0 && (
                                <div className="flex flex-col">
                                    <div className="text-lg font-medium">Committee Memberships</div>
                                    <hr className="h-0.5 my-3 bg-slate-100 rounded" />
                                    <div className="flex flex-row gap-4">
                                        <ul>
                                            {displayData
                                                .slice(0, Math.floor(displayData.length / 2))
                                                .map((cmte: string, i: number) => (
                                                    <CommitteeItem
                                                        key={i}
                                                        cmte={cmte}
                                                        cmteMap={committees}
                                                        type={type}
                                                    />
                                                ))}
                                        </ul>
                                        <ul>
                                            {displayData
                                                .slice(Math.floor(displayData.length / 2))
                                                .map((cmte: string, i: number) => (
                                                    <CommitteeItem
                                                        key={i}
                                                        cmte={cmte}
                                                        cmteMap={committees}
                                                        type={type}
                                                    />
                                                ))}
                                        </ul>
                                    </div>
                                </div>
                            )}
                        </div>
                    </Grow>
                ) : (
                    <></>
                )}
            </div>
        </Fade>
    );
};

export default MPBioInfo;
