import { last_month, three_months_ago, two_months_ago } from '../../constants/date_time';
import { EntityType } from '../config/Entities';

const FIELD_GROUPS = {
    monthly_lobbying_fields: ['two_months_ago', 'last_month', 'change_abs', 'change_rel', 'mtd'],
    yearly_lobbying_fields: ['ttm'],
    yearly_lobbying_fields_plus: ['t36m'],
    registration_fields: ['num_active_registrations'],
    mp_social_fields: ['constituency', 'province_territory', 'affiliation'],
    senator_social_fields: ['province_territory', 'affiliation'],
};

const FIELD_TYPES = {
    generic: [...FIELD_GROUPS.monthly_lobbying_fields, ...FIELD_GROUPS.yearly_lobbying_fields],
    generic_with_registrations: [
        ...FIELD_GROUPS.monthly_lobbying_fields,
        ...FIELD_GROUPS.yearly_lobbying_fields,
        ...FIELD_GROUPS.registration_fields,
    ],
    generic_yearly: [
        ...FIELD_GROUPS.yearly_lobbying_fields,
        ...FIELD_GROUPS.yearly_lobbying_fields_plus,
    ],
    nil: [],
    mp: [...FIELD_GROUPS.mp_social_fields, ...FIELD_GROUPS.yearly_lobbying_fields],
    senator: [...FIELD_GROUPS.senator_social_fields, ...FIELD_GROUPS.yearly_lobbying_fields],
};

const entity_field_type_map = new Map<EntityType, string[]>();

for (const key of [
    EntityType.Sector,
    EntityType.Institution,
    EntityType.DPOH,
    EntityType.SenateCommittee,
    EntityType.Subject,
    EntityType.Organization,
    EntityType.MP,
]) {
    entity_field_type_map.set(key, FIELD_TYPES.generic);
}
for (const key of [EntityType.SenateCommittee, EntityType.Committee, EntityType.Issues]) {
    entity_field_type_map.set(key, FIELD_TYPES.nil);
}
entity_field_type_map.set(EntityType.Firm, FIELD_TYPES.generic_with_registrations);
entity_field_type_map.set(EntityType.Consultant, FIELD_TYPES.generic_with_registrations);
entity_field_type_map.set(EntityType.InactiveOrganization, FIELD_TYPES.generic_yearly);
entity_field_type_map.set(EntityType.InstitutionMP, FIELD_TYPES.mp);
entity_field_type_map.set(EntityType.SenateMember, FIELD_TYPES.senator);

const data_key_common_name_map = new Map();

data_key_common_name_map.set('two_months_ago', three_months_ago.slice(0, 3));
data_key_common_name_map.set('last_month', two_months_ago.slice(0, 3));
data_key_common_name_map.set('change_abs', '+/-');
data_key_common_name_map.set('change_rel', '%');
data_key_common_name_map.set('mtd', last_month.slice(0, 3));
data_key_common_name_map.set('ttm', '12M');
data_key_common_name_map.set('ttm', 'Comm reports (12M)');
data_key_common_name_map.set('t36m', '36M');
data_key_common_name_map.set('num_active_registrations', 'registrations');

const common_name_data_key_map = new Map();

for (const key of [
    'sectors',
    'institutions',
    'DPOHs',
    'senators',
    'MPs',
    'committees',
    'senate committees',
    'subjects',
    'issues',
    'organizations',
    'inactive organizations',
    'firms',
    'consultants',
    'institution MPs',
]) {
    common_name_data_key_map.set(key, 'title');
}
common_name_data_key_map.set(three_months_ago.slice(0, 3), 'two_months_ago');
common_name_data_key_map.set(two_months_ago.slice(0, 3), 'last_month');
common_name_data_key_map.set('+/-', 'change_abs');
common_name_data_key_map.set('%', 'change_rel');
common_name_data_key_map.set(last_month.slice(0, 3), 'mtd');
common_name_data_key_map.set('Comm reports (12M)', 'ttm');
common_name_data_key_map.set('12M', 'ttm');
common_name_data_key_map.set('36M', 't36m');
common_name_data_key_map.set('registrations', 'num_active_registrations');
common_name_data_key_map.set('constituency', 'constituency');
common_name_data_key_map.set('province / territory', 'province_territory');
common_name_data_key_map.set('affiliation', 'affiliation');

export const ENTITY_CATALOGUE_FIELD_MAP = entity_field_type_map;
export const ENTITY_CATALOGUE_DATA_KEY_NAME_MAP = data_key_common_name_map;
export const ENTITY_CATALOGUE_NAME_DATA_KEY_MAP = common_name_data_key_map;
