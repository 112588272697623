import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from '../../../store/store';
import {
    entityList,
    entityListExtra,
    ministerList,
} from '../../../interfaces/generic-entity.interface';
import { percentage_change_num } from '../../../helpers/percentage_change';

interface InstitutionListState {
    institution_list: entityListExtra[] | null;
    institution_list_loading: boolean;
    dpoh_list: entityListExtra[] | null;
    dpoh_list_loading: boolean;
    // lists for trial dropdowns
    dpoh_lists: entityListExtra[][];
}

const initialState: InstitutionListState = {
    institution_list: null,
    institution_list_loading: false,
    dpoh_list: null,
    dpoh_list_loading: false,
    dpoh_lists: [[], [], []],
};

const baseURL = process.env.REACT_APP_API_BASE_URL;

export const fetchInstitutionList = createAsyncThunk(
    'institution/fetchList',
    async (): Promise<entityListExtra[]> => {
        const response = await axios.get(`${baseURL}/inst/list`);
        const data: entityList[] = response.data.institution;
        const converted_data: entityListExtra[] = data.map((entry) => {
            return {
                ...entry,
                // Calculate with potential nulls replaced by 0
                change_abs: (entry.last_month || 0) - (entry.two_months_ago || 0),
                change_rel: percentage_change_num(entry.two_months_ago || 0, entry.last_month || 0),
                available: true,
            };
        });
        // todo move filter logic to backend
        return converted_data
            .filter((institution) => institution.code !== 128)
            .sort((a, b) => a.title.localeCompare(b.title));
    }
);

export const fetchDPOHList = createAsyncThunk(
    'institution/fetchDPOHList',
    async ({
        code,
        titleFilter,
    }: {
        code: string | undefined;
        titleFilter?: string | undefined;
    }): Promise<entityListExtra[]> => {
        const isSearchingForMembersOfParliament =
            code === '103' && titleFilter === 'Member of Parliament';

        const fieldsParam = isSearchingForMembersOfParliament ? 'mpSocial' : undefined;
        const ttmLimit = isSearchingForMembersOfParliament ? 1 : 10;

        let response;
        if (isSearchingForMembersOfParliament) {
            response = await axios.get(`${baseURL}/parliament/house/members`);
        } else {
            response = await axios.get(`${baseURL}/dpoh/${code}/list?showFields=${fieldsParam}`);
        }

        const data: entityList[] = response.data.dpohs;

        // Get the most recent minister and deputy minister for this institution
        const minister_response = await axios.get(`${baseURL}/inst/${code}/mostRecentMinister`);
        const minister_data: string[] = minister_response.data.ministers.map(
            (entry: ministerList) => entry.name
        );

        const converted_data: entityListExtra[] = data
            .filter((entry) => entry.ttm >= ttmLimit || minister_data.includes(entry.title))
            .map((entry) => {
                return {
                    ...entry,
                    // Calculate with potential nulls replaced by 0
                    change_abs: (entry.last_month || 0) - (entry.two_months_ago || 0),
                    change_rel: percentage_change_num(
                        entry.two_months_ago || 0,
                        entry.last_month || 0
                    ),
                    available: true,
                    title2: entry.title2 ?? undefined,
                    affiliation: entry.affiliation ?? 'N/A',
                    constituency: entry.constituency ?? 'N/A',
                    province_territory: entry.province_territory ?? 'N/A',
                };
            });
        return converted_data;
    }
);

export const fetchMultiDPOHList = createAsyncThunk(
    'institution/fetchMultiDPOHList',
    async ({
        codes,
        titleFilter,
    }: {
        codes: string[];
        titleFilter?: string | undefined;
    }): Promise<entityListExtra[][]> => {
        let aggregateData: any[] = [];

        for (let i in codes) {
            if (codes[i] !== null) {
                const response = await axios.get(`${baseURL}/dpoh/${codes[i]}/list`);
                const data: entityList[] = response.data.dpohs;

                // Get the most recent minister and deputy minister for this institution
                const minister_response = await axios.get(
                    `${baseURL}/inst/${codes[i]}/mostRecentMinister`
                );
                const minister_data: string[] = minister_response.data.ministers.map(
                    (entry: ministerList) => entry.name
                );

                const converted_data: entityListExtra[] = data
                    // Filter the dpoh's government title (title2) by the given value
                    .filter((entry) => (titleFilter ? entry.title2 === titleFilter : entry))
                    .filter((entry) => entry.ttm >= 10 || minister_data.includes(entry.title))
                    .map((entry) => {
                        return {
                            ...entry,
                            // Calculate with potential nulls replaced by 0
                            change_abs: (entry.last_month || 0) - (entry.two_months_ago || 0),
                            change_rel: percentage_change_num(
                                entry.two_months_ago || 0,
                                entry.last_month || 0
                            ),
                            available: true,
                            title2: undefined,
                        };
                    });
                aggregateData.push(converted_data);
            } else {
                aggregateData.push([]);
            }
        }

        return aggregateData;
    }
);

const institutionListSlice = createSlice({
    name: 'institutionListReducer',
    initialState,
    reducers: {
        clearDPOHListsAt(state, action: PayloadAction<number>) {
            state.dpoh_lists = initialState.dpoh_lists?.splice(action.payload, 1, []);
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchInstitutionList.pending, (state) => {
                state.institution_list_loading = true;
            })
            .addCase(fetchInstitutionList.fulfilled, (state, action) => {
                state.institution_list_loading = false;
                state.institution_list = action.payload;
            })
            .addCase(fetchInstitutionList.rejected, (state) => {
                state.institution_list_loading = false;
                state.institution_list = null;
            })
            .addCase(fetchDPOHList.pending, (state) => {
                state.dpoh_list_loading = true;
            })
            .addCase(fetchDPOHList.fulfilled, (state, action) => {
                state.dpoh_list_loading = false;
                state.dpoh_list = action.payload;
            })
            .addCase(fetchDPOHList.rejected, (state) => {
                state.dpoh_list_loading = false;
                state.dpoh_list = null;
            })
            .addCase(fetchMultiDPOHList.pending, (state) => {
                state.dpoh_list_loading = true;
            })
            .addCase(fetchMultiDPOHList.fulfilled, (state, action) => {
                state.dpoh_list_loading = false;
                state.dpoh_lists = action.payload;
            })
            .addCase(fetchMultiDPOHList.rejected, (state) => {
                state.dpoh_list_loading = false;
                state.dpoh_lists = initialState.dpoh_lists;
            });
    },
});

export const { clearDPOHListsAt } = institutionListSlice.actions;

export const selectInstitutionList = (state: RootState) => state.institutionList.institution_list;
export const selectInstitutionListLoading = (state: RootState) =>
    state.institutionList.institution_list_loading;

export const selectDPOHList = (state: RootState) => state.institutionList.dpoh_list;
export const selectDPOHListLoading = (state: RootState) => state.institutionList.dpoh_list_loading;

export const selectDPOHLists = (state: RootState) => state.institutionList.dpoh_lists;

export default institutionListSlice.reducer;
